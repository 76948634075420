import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Optional } from '@angular/core';
import {
  FormsModule,
  FormArray,
  FormControl,
  FormGroup,
} from '@angular/forms/';
import { ReactiveFormsModule } from '@angular/forms/';
import { ApplicationServiceService } from './Services/application-service.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppheaderComponent } from './appheader/appheader.component';
import { AppfooterComponent } from './appfooter/appfooter.component';
import { SIMRegistrationComponent } from './simregistration/simregistration.component';
import { SImCardRegistrationContComponent } from './sim-card-registration-cont/sim-card-registration-cont.component';
import { SImCardRegistrationFinalComponent } from './sim-card-registration-final/sim-card-registration-final.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CustomercareloginComponent } from './customercarelogin/customercarelogin.component';
import { ViewPlanComponent } from './view-plan/view-plan.component';
import { SIMCardStatusComponent } from './simcard-status/simcard-status.component';
import { MaterialModule } from './material.module';
import { FileUploadServiceService } from '../app/FileService/file-upload-service.service';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { TransformStringFilter } from './Services/TransformFilter';
import {
  MAT_DATE_LOCALE,
  DateAdapter,
  MAT_DATE_FORMATS,
} from '@angular/material/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TermandConditionComponent } from './termand-condition/termand-condition.component';
import { GiftvoucherComponent } from './giftvoucher/giftvoucher.component';

@NgModule({
  declarations: [
    AppComponent,
    AppheaderComponent,
    AppfooterComponent,
    SIMRegistrationComponent,
    CustomercareloginComponent,
    ViewPlanComponent,
    SIMCardStatusComponent,
    SImCardRegistrationContComponent,
    SImCardRegistrationFinalComponent,
    TermandConditionComponent,
    GiftvoucherComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatInputModule,
    MatFormFieldModule,
    MatNativeDateModule,
  ],
  providers: [
    ApplicationServiceService,
    FileUploadServiceService,
    DatePipe,
    MatDatepickerModule,
    TransformStringFilter,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
