import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SIMRegistrationComponent } from './simregistration/simregistration.component';
import { CustomercareloginComponent } from './customercarelogin/customercarelogin.component';
import { SIMCardStatusComponent } from './simcard-status/simcard-status.component';
import { ViewPlanComponent } from './view-plan/view-plan.component';
import { SImCardRegistrationContComponent } from './sim-card-registration-cont/sim-card-registration-cont.component';
import { SImCardRegistrationFinalComponent } from './sim-card-registration-final/sim-card-registration-final.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
import { GiftvoucherComponent } from './giftvoucher/giftvoucher.component';

const routes: Routes = [
  { path: 'SIMCardRegistration', component: SIMRegistrationComponent },
  {
    path: 'SIMCardRegistration/:RegistrationID',
    component: SIMRegistrationComponent,
  },
  { path: '*/:RegistrationID', component: SIMRegistrationComponent },
  { path: '#', component: SIMRegistrationComponent },
  { path: '', component: SIMRegistrationComponent },
  {
    path: 'SIMCardRegistrationCont/:RegistrationID/Next',
    component: SImCardRegistrationContComponent,
  },
  {
    path: 'SIMCardRegistrationFinal/:RegistrationID/Final',
    component: SImCardRegistrationFinalComponent,
  },
  { path: 'Thankyou/:RegistrationID', component: ThankyouComponent },
  { path: 'giftvoucher', component: GiftvoucherComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
