import { Guid } from 'guid-typescript';

export class giftvoucherRequest {
  PersonID?: Guid;
  Id: number = 0;
  FirstName: string;
  MiddleName: string;
  LastName: string;
  Email: string;
  IContactNumber: string;
  InternationalNumber: string;
  DocumentID?: Guid;
  CreatedOn?: Date;
  UpdateOn?: Date;
  UpdatedCount?: number = 0;
}
