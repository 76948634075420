import { Component, OnInit } from '@angular/core';
import {
  SIMRegistrationResponse,
  UserFiles,
  FileUploadModel,
} from '../Models/SIMRegistrationResponse';
import { ApplicationServiceService } from '../Services/application-service.service';
import { giftvoucherRequest } from '../Models/giftrequest';
import { Guid } from 'guid-typescript';
@Component({
  selector: 'app-giftvoucher',
  templateUrl: './giftvoucher.component.html',
  styleUrls: ['./giftvoucher.component.scss'],
})
export class GiftvoucherComponent implements OnInit {
  FileUploadModel: FileUploadModel;
  IsFileUploaded: boolean;
  ActionMessageVisa: string;
  ActionMessageFront: string;
  ActionMessageBack: string;
  SuccessMessage: boolean;
  giftvoucherRequest: giftvoucherRequest;
  personid: any;
  Requestsuccessmessage: string = '';
  constructor(public ApplicationServiceService: ApplicationServiceService) {}

  ngOnInit(): void {
    this.personid = this.GetPersonID(); //
    this.giftvoucherRequest = new giftvoucherRequest();
    this.giftvoucherRequest.PersonID = this.personid;
  }

  GetPersonID() {
    var getguid = Guid.create();
    return getguid.toString();
  }

  handleFileInput(
    files: FileList,
    Type: any,
    giftvoucherRequest: giftvoucherRequest
  ) {
    this.FileUploadModel = new FileUploadModel();

    if (
      giftvoucherRequest.Email === '' ||
      giftvoucherRequest.Email === null ||
      giftvoucherRequest.Email === undefined
    ) {
      alert('Provide the email id');
      giftvoucherRequest.MiddleName = null;
      return;
    }
    if (
      giftvoucherRequest.IContactNumber === '' ||
      giftvoucherRequest.IContactNumber === null ||
      giftvoucherRequest.IContactNumber === undefined
    ) {
      alert('Provide the Indian contact number');
      giftvoucherRequest.MiddleName = null;
      return;
    }

    this.FileUploadModel.fileToUpload = files.item(0);
    let getFileSize = this.FileUploadModel.fileToUpload.size / 1024 / 1024;
    let getFileSizeinMB = parseInt(String(getFileSize));
    if (getFileSizeinMB >= 2.5) {
      if (Type === 'PaymentReceipt') {
        this.IsFileUploaded = false;
        this.ActionMessageVisa = 'Oops Only 2 MB are allowed to Upload';
        giftvoucherRequest.MiddleName = null;
        console.log();
        setTimeout(
          function () {
            this.ActionMessageVisa = '';
          }.bind(this),
          7000
        );
      }
      return;
    }
    this.FileUploadModel.SIMRegistrationID = this.personid;
    this.FileUploadModel.id = '0';
    this.FileUploadModel.uploadtype = giftvoucherRequest.IContactNumber;
    this.FileUploadModel.userID = giftvoucherRequest.Email;
    this.ApplicationServiceService.VoucherPostFile(
      this.FileUploadModel
    ).subscribe((result: any) => {
      this.SuccessMessage = true;
      if (Type === 'PaymentReceipt') {
        this.ActionMessageVisa = 'File has been uploaded.!';
        this.IsFileUploaded = true;
      }
      setTimeout(
        function () {
          this.SuccessMessage = false;
        }.bind(this),
        5000
      );
    });
  }

  ValidateMobilenumber(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  onSubmit(voucherRequest: giftvoucherRequest) {
    voucherRequest.DocumentID = this.personid;
    voucherRequest.UpdateOn = new Date();
    voucherRequest.CreatedOn = new Date();
    voucherRequest.UpdatedCount = 0;
    voucherRequest.MiddleName = '';
    this.ApplicationServiceService.VoucherRequest(voucherRequest).subscribe(
      (result) => {
        if (result) {
          this.Requestsuccessmessage = `Thank you for registering your request for the Gift Voucher. Please expect our revert within 7 working days. Please reach out to giftvoucher@koodosimindia.com for any queries`;
          this.personid = this.GetPersonID(); //
          this.giftvoucherRequest = new giftvoucherRequest();
          this.giftvoucherRequest.PersonID = this.personid;

          setTimeout(
            function () {
              this.Requestsuccessmessage = '';
            }.bind(this),
            8000
          );
        }
      }
    );
  }
}
