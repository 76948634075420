import { SIMRegistration } from "./simregistration";

export class SIMRegistrationSec extends SIMRegistration  {
  SImNumber: string;
  PlanID: number;
  SIMRegistrationID: string;
  id: number;
  DateofTravel?: Date;
  DateofTravelTentative: string;
  EmailID: string;
  SourceName: string = 'Koodo-Postpaid';
  MobileNumber: string;
  Canadianaddress: string;
  Province: string;
  PostalCode: string;
  UniversityName: string;
  HandsetModelnumber: string;
  passportfilename: string;
  passportbackfilename: string;
  visafilename: string;
  simPlan: string;
}
