import { Component, OnInit, NgModule } from '@angular/core';
import { FormsModule, Form } from '@angular/forms/';
import { ReactiveFormsModule, Validators } from '@angular/forms/';
import { CommonModule } from '@angular/common';
import { SIMRegistration } from '../Models/simregistration';
import { SIMRegistrationSec } from '../Models/simregistration-sec';
import { ApplicationServiceService } from '../Services/application-service.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import {
  SIMRegistrationResponse,
  UserFiles,
  FileUploadModel,
} from '../Models/SIMRegistrationResponse';

import { SIMValidateResponse } from '../Models/SIMValidateResponse';
import { SIMAndPaymentResponse } from '../Models/SIMAndPaymentResponse';

@Component({
  selector: 'app-sim-card-registration-cont',
  templateUrl: './sim-card-registration-cont.component.html',
  styleUrls: ['./sim-card-registration-cont.component.scss'],
})
export class SImCardRegistrationContComponent implements OnInit {
  public RegistrationID: string;
  public SIMRegistrationSecModel: SIMRegistrationSec;
  public SIMRegistration: SIMRegistration;
  public SIMCardStatus: boolean = false;
  public SIMRegistrationResponseModel: SIMRegistrationResponse;
  public SImNo: string;
  public IsValidSIMNumber: boolean = true;
  public DisplayPlanMessage: boolean = false;
  public Startdate: Date;
  public DisplayMandatoryDiv: boolean = false;
  public SIMValidateResponse: SIMValidateResponse;
  public DisplayPlanMessageKoodoo: boolean = false;
  public IsSubmitted: boolean = false;
  public NetworkName: string = 'Koodo-Postpaid';
  public SIMAndPaymentResponse: SIMAndPaymentResponse;
  FileUploadModel: FileUploadModel;
  ActionMessageFront: string;
  ActionMessageBack: string;
  ActionMessageVisa: string;
  SuccessMessage: boolean;
  constructor(
    public ApplicationServiceService: ApplicationServiceService,
    public route: ActivatedRoute,
    public Router: Router
  ) {
    this.route.params.subscribe(
      (params) => (this.RegistrationID = params['RegistrationID'])
    );
  }
  IsFileUploaded = false;

  public ProvinceList: any = [
    'Alberta',
    'British Columbia',
    'Manitoba',
    'New Brunswick',
    'Newfoundland and Labrador',
    'Northwest Territories',
    'Nova Scotia',
    'Nunavut',
    'Ontario',
    'Prince Edward Island',
    'Quebec',
    'Saskatchewan',
    'Yukon',
  ];

  ngOnInit(): void {
    this.getodoPlanList();
    this.Startdate = new Date();
    this.SIMRegistrationSecModel = new SIMRegistrationSec();
    this.SIMRegistrationSecModel.simPlan = '$30 Plan-50 GB Data';
    this.SIMRegistrationResponseModel = new SIMRegistrationResponse();
    this.koodoPostpaidPlanType = [];
    this.koodoPostpaidPlaninformtion = null;
    this.SIMRegistrationSecModel.SIMRegistrationID = this.RegistrationID;
    this.SIMRegistrationSecModel.DateofTravelTentative = 'Confirmed';
    this.BindDataTotheModelWhenPreviousClick(this.RegistrationID);
    this.PlanDetailonPlanSelection(this.SIMRegistrationSecModel.simPlan, '');
  }

  ValidatetheSIMCard(
    SIMNumber: string,
    SIMRegistrationSecModel: SIMRegistrationSec
  ) {
    this.DisplayPlanMessage = false;
    this.DisplayMandatoryDiv = false;
    this.DisplayPlanMessageKoodoo = false;
    this.DisplayMandatoryDiv = false;
    this.SIMAndPaymentResponse = new SIMAndPaymentResponse();

    if (SIMNumber.length < 15) {
      return;
    }

    // Get Mobile Number
    this.ApplicationServiceService.SIMCardValidationByNetwork(
      SIMNumber,
      this.NetworkName
    ).subscribe((result: any) => {
      if (result.ResponseMessage === 'Valid') {
        this.ApplicationServiceService.SIMCardNumberANDPayments(
          SIMNumber
        ).subscribe((result: any) => {
          this.SIMAndPaymentResponse = result;
          this.SIMRegistrationSecModel.MobileNumber =
            this.SIMAndPaymentResponse.MobileNumber;
        });

        this.SIMCardStatus = false;
        this.IsValidSIMNumber = false;
        if (result.Network === 'Fido') {
          this.DisplayPlanMessage = true;
          this.DisplayMandatoryDiv = true;
        } else if (result.Network === 'Koodo-Postpaid') {
          this.DisplayPlanMessage = true;
          this.DisplayMandatoryDiv = true;
          this.PlanDetailonPlanSelection(this.SIMRegistrationSecModel.simPlan, '');
        }
      } else if (result.ResponseMessage === 'InValid') {
        this.SIMCardStatus = true;
        this.IsValidSIMNumber = true;
      }
    });
  }

  OnSubmit(SIMRegistrationSecModel: SIMRegistrationSec) {
    this.IsSubmitted = true;
    if (
      SIMRegistrationSecModel.DateofTravel == undefined ||
      SIMRegistrationSecModel.DateofTravel == null
    ) {
      alert('Please provide the travel date.!');
      return;
    }

    if (
      SIMRegistrationSecModel.EmailID == undefined ||
      SIMRegistrationSecModel.EmailID == null ||
      SIMRegistrationSecModel.EmailID == ''
    ) {
      alert('Please provide the email id.!');
      return;
    }

    if (
      SIMRegistrationSecModel.Canadianaddress == undefined ||
      SIMRegistrationSecModel.Canadianaddress == null ||
      SIMRegistrationSecModel.Canadianaddress == ''
    ) {
      alert('Please provide the address.!');
      return;
    }
    if (
      SIMRegistrationSecModel.Province == undefined ||
      SIMRegistrationSecModel.Province == null ||
      SIMRegistrationSecModel.Province == ''
    ) {
      alert('Please provide the Province.!');
      return;
    }
    if (
      SIMRegistrationSecModel.PostalCode == undefined ||
      SIMRegistrationSecModel.PostalCode == null ||
      SIMRegistrationSecModel.PostalCode == ''
    ) {
      alert('Please provide the Postal Code.!');
      return;
    }
    if (this.IsFileUploaded === false) {
      alert('Please upload the required file.!');
      return;
    }
    this.route.params.subscribe(
      (params) => (this.RegistrationID = params['RegistrationID'])
    );

    this.SIMRegistrationSecModel = new SIMRegistrationSec();
    this.SIMRegistrationSecModel.PlanID = 0;
    this.SIMRegistrationSecModel.SIMRegistrationID = this.RegistrationID;
    this.SIMRegistrationSecModel.id = 0;
    this.SIMRegistrationSecModel.SImNumber = SIMRegistrationSecModel.SImNumber;
    this.SIMRegistrationSecModel.DateofTravel =
      SIMRegistrationSecModel.DateofTravel;
    this.SIMRegistrationSecModel.DateofTravelTentative =
      SIMRegistrationSecModel.DateofTravelTentative;
    this.SIMRegistrationSecModel.EmailID = SIMRegistrationSecModel.EmailID;
    this.SIMRegistrationSecModel.Canadianaddress =
      SIMRegistrationSecModel.Canadianaddress;
    this.SIMRegistrationSecModel.PostalCode =
      SIMRegistrationSecModel.PostalCode;
    this.SIMRegistrationSecModel.UniversityName =
      SIMRegistrationSecModel.UniversityName;
    this.SIMRegistrationSecModel.HandsetModelnumber =
      SIMRegistrationSecModel.HandsetModelnumber;
    this.SIMRegistrationSecModel.Province = SIMRegistrationSecModel.Province;
    this.SIMRegistrationSecModel.simPlan = SIMRegistrationSecModel.simPlan;
    this.ApplicationServiceService.AddSIMRegistrationSec(
      this.SIMRegistrationSecModel,
      this.RegistrationID
    ).subscribe((result: any) => {
      this.SIMRegistrationResponseModel = JSON.parse(result);
      this.Router.navigate(['/Thankyou/' + this.RegistrationID]);

      // this.Router.navigateByUrl('/SIMCardRegistratioFinal/' + this.SIMRegistrationResponseModel.SIMRegistrationID + '/Final');
      // this.Router.navigate(['/SIMCardRegistrationFinal/' + this.RegistrationID + '/Final']);
    });
  }

  OnBackButton() {
    this.route.params.subscribe(
      (params) => (this.RegistrationID = params['RegistrationID'])
    );
    this.Router.navigate(['/SIMCardRegistration/' + this.RegistrationID]);
    // this.Router.navigateByUrl('/SIMCardRegistration/' + this.RegistrationID);
  }

  NewSaleEntry() {
    this.route.params.subscribe(
      (params) => (this.RegistrationID = params['RegistrationID'])
    );
    this.Router.navigate(['/SIMCardRegistration']);
    // this.Router.navigateByUrl('/SIMCardRegistration/' + this.RegistrationID);
  }

  BindDataTotheModelWhenPreviousClick(RegistrationID: string) {
    this.SIMRegistrationSecModel = new SIMRegistrationSec();
    if (RegistrationID != null && RegistrationID != '') {
      this.ApplicationServiceService.GetSIMRegistrationDetail(
        RegistrationID
      ).subscribe((result: any) => {
        this.SIMRegistrationResponseModel = JSON.parse(result);
        this.SIMRegistrationSecModel.SIMRegistrationID =
          this.SIMRegistrationResponseModel.SIMRegistrationID;
        this.SIMRegistrationSecModel.SImNumber =
          this.SIMRegistrationResponseModel.SIMCardNumberICCID;
        this.SIMRegistrationSecModel.PlanID =
          this.SIMRegistrationResponseModel.PlanID;
        this.SIMRegistrationSecModel.id = this.SIMRegistrationResponseModel.id;
        //
        this.SIMRegistrationSecModel.FirstName =
          this.SIMRegistrationResponseModel.FirstName;
        this.SIMRegistrationSecModel.LastName =
          this.SIMRegistrationResponseModel.LastName;
        this.SIMRegistrationSecModel.Indianmobilenumber =
          this.SIMRegistrationResponseModel.Indianmobilenumber.replace(
            '+91',
            ''
          );
        this.SIMRegistrationSecModel.simPlan = '$30 Plan-50 GB Data';

        if (
          this.SIMRegistrationSecModel.SImNumber != undefined &&
          this.SIMRegistrationSecModel.SImNumber != null &&
          this.SIMRegistrationSecModel.SImNumber != ''
        ) {
          this.ValidatetheSIMCard(this.SIMRegistrationSecModel.SImNumber,  this.SIMRegistrationSecModel);
        }
        if (
          this.SIMRegistrationSecModel.DateofTravelTentative == undefined ||
          this.SIMRegistrationSecModel.DateofTravelTentative == null ||
          this.SIMRegistrationSecModel.SImNumber == ''
        ) {
          this.SIMRegistrationSecModel.DateofTravelTentative = 'Confirmed';
        }
      });
    } else {
      this.SIMRegistrationSecModel.DateofTravelTentative = 'Confirmed';
    }
  }

  handleFileInputPassportFront(files: FileList, Type: any) {
    this.FileUploadModel = new FileUploadModel();
    this.FileUploadModel.fileToUpload = files.item(0);
    let getFileSize = this.FileUploadModel.fileToUpload.size / 1024 / 1024;
    let getFileSizeinMB = parseInt(String(getFileSize));
    if (getFileSizeinMB >= 2.5) {
      if (Type === 'Visa') {
        this.IsFileUploaded = false;
        this.ActionMessageVisa = 'Only 2 MB is allowed to Upload.!';
      } else if (Type === 'PassportFront') {
        this.IsFileUploaded = false;
        this.ActionMessageFront = 'Only 2 MB is allowed to Upload.!';
      } else if (Type === 'PassportBack') {
        this.IsFileUploaded = false;
        this.ActionMessageBack = 'Only 2 MB is allowed to Upload.!';
      }
      return;
    }
    this.FileUploadModel.SIMRegistrationID = this.RegistrationID;
    this.FileUploadModel.id = '0';
    this.FileUploadModel.uploadtype = Type;
    this.FileUploadModel.userID = '3';
    this.ApplicationServiceService.PostFile(this.FileUploadModel).subscribe(
      (result: any) => {
        this.SuccessMessage = true;
        if (Type === 'Visa') {
          this.ActionMessageVisa = 'File has been uploaded.!';
          this.IsFileUploaded = true;
        } else if (Type === 'PassportFront') {
          this.ActionMessageFront = 'File has been uploaded.!';
          this.IsFileUploaded = true;
        } else if (Type === 'PassportBack') {
          this.ActionMessageBack = 'File has been uploaded.!';
          this.IsFileUploaded = true;
        }
        setTimeout(
          function () {
            this.SuccessMessage = false;
          }.bind(this),
          5000
        );
      }
    );
  }

  public koodoPostpaidPlanType: any = [];

  getodoPlanList() {
    this.ApplicationServiceService.GetKoodoplanList().subscribe(
      (result: any) => {
        const GetResult = result.map((item: any) => {
          return { value: item.id, label: item.planName };
        });
        this.koodoPostpaidPlanType = GetResult;
      }
    );
  }
  public koodoPostpaidPlaninformtion: any;

  GetPlanByName(PlanName: string) {
    this.ApplicationServiceService.GetKoodoplanByName(PlanName).subscribe(
      (result: any) => {
        this.koodoPostpaidPlaninformtion = result;
      }
    );
  }

  PlanDetailonPlanSelection(selectedplan: any, type: any) {
    if (type === 'UI') {
      let splitted: string = selectedplan.target.value.split(':')[1];
      this.GetPlanByName(splitted);
    } else {
      let splitted: string = selectedplan;
      this.GetPlanByName(splitted);
    }
  }
}
