<div class="container">
    <form #sf="ngForm" id="sf" novalidate>
        <div class="row">
            <div class="col-md-10 offset-md-1 shadow-lg px-md-5 py-sm-5 border bg-white">
                <h4 class="secondheader mb-4">Enter SIM number to get Plan detail<sup class="text-danger"> *</sup> </h4>
                <p class="secondheader2">(Starts with 89 and contains 20 digits) </p>

                <div class="row align-items-center">
                    <div class="col-md-6">
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" name="SImNumber" id="SImNumber" placeholder="SIM No"
                                [(ngModel)]="SIMRegistrationSecModel.SImNumber" required #SImNumber="ngModel" ngModel
                                (ngModelChange)="ValidatetheSIMCard($event, SIMRegistrationSecModel)">
                            <button class="btn btn-danger" type="button" id="button-addon2"
                                (click)="ValidatetheSIMCard(SIMRegistrationSecModel.SImNumber, SIMRegistrationSecModel)">Go</button>
                        </div>

                    </div>
                    <div class="col-md-6">
                        <img class="img-fluid" src="../../assets/AppImages/sim-large.png">
                    </div>
                    <div *ngIf="!SImNumber?.valid && (SImNumber?.dirty || SImNumber?.touched)">
                        SIM number required
                    </div>
                    <div class="alert alert-danger" *ngIf="SIMCardStatus">
                       
                        Please provide the valid sim card.!
                        <br>
                        <button type="button" class="btn btn-danger" (click)="NewSaleEntry()">
                            Back to home</button>
                    </div>
                </div>
                <div id="DisplayPlanDetails" *ngIf="DisplayPlanMessage">

                    <div class="row">
                        <div class="col-md-3">
                            <label for="plantype" class="col-form-label pb-0 textbold">Select Plan type</label> 
                        </div>
                    <div class="col-md-5" >
 <select class="form-select" aria-label="Default select example" required="true" (change)="PlanDetailonPlanSelection($event, 'UI')"
              [(ngModel)]="SIMRegistrationSecModel.simPlan" name="simPlan" id="simPlan"
              #simPlan="ngModel">
              <option *ngFor="let Item of koodoPostpaidPlanType" [ngValue]="Item.label" [selected]="Item.label === '$30 Plan-50 GB Data'">
                {{Item.label}}
              </option>
            </select>
                    <div
                        *ngIf="!simPlan?.valid && (simPlan?.dirty || simPlan?.touched)">
                         Plan type
                    </div>
                </div>
            </div>
                    <div class="row">
                        <h4 class="secondheader sc-color my-3 text-start">Plan Details:</h4>
                    </div>
                    <p>
                        {{koodoPostpaidPlaninformtion?.planDetails[0]?.planDetail}}
                    </p>
                    <ul>
                        <li *ngFor="let planitem of koodoPostpaidPlaninformtion?.planDetails.slice(1)">{{planitem.planDetail}}</li>
                    </ul>
                    <div>
                        <h3>
                        <p>
                            <b> This is a special discounted price plan provides and offers huge saving in cost as compared to the usual plans offered by Koodo
                           <br> Your first Postpaid invoice will be generated by Koodo (Telus network) and will be payable after 20 days</b>
                        </p>                      
                        </h3>
                    </div>
                    <div>
                        <h3>
                         
                        </h3>
                    </div>
                </div>
                <div id="DisplayPlanDetailsKoodoo" *ngIf="DisplayPlanMessageKoodoo">
                    <div class="row">
                        <h4 class="secondheader sc-color my-3 text-start">Plan Details:</h4>
                    </div>
                    <p>
                        This is a Prepaid plan of $45 from Koodoo (Telus). No payment is required and the plan is
                        complimentary for
                        first month. The SIM will be activated the moment you land in Canada and insert in mobile. For
                        the mobile
                        number please call another mobile and make note of it. The automated SMS takes a few hours to be
                        received. The plan includes:
                    </p>
                    <ul>
                        <li>5GB internet at 4G speed</li>
                        <li>Unlimited Canada wide calling</li>
                        <li>100 minutes to India</li>
                    </ul>
                    <div>
                        <h3>
                            <b> The plan is complimentary for first month and the same can be renewed on the Koodoo
                                website or through
                                any retailer</b>
                        </h3>
                    </div>
                </div>

                <div id="mandatoryfield" *ngIf="DisplayMandatoryDiv">

                    <div class="row mb-4 align-items-center" style="display:none;">
                        <div class="col-md-4">
                            <label for="date" class="col-form-label textbold">Mobile Number<sup
                                    class="text-danger">*</sup></label>
                        </div>
                        <div class="col-md-5">
                            <div class="row">
                                <div clas="col-md-5">

                                    <div class="input-group">
                                        <input placeholder="Mobile Number" id="MobileNumber" name="MobileNumber"
                                            class="form-control" [(ngModel)]="SIMRegistrationSecModel.MobileNumber"
                                            #MobileNumber="ngModel"  readonly [ngClass]="{
                                    'is-invalid': (MobileNumber?.invalid && IsSubmitted),
                                    'valid': !MobileNumber?.valid && (MobileNumber?.dirty || MobileNumber?.touched)
                               }">

                                    </div>
                                </div>
                            </div>
                            <div *ngIf="!MobileNumber?.valid && (MobileNumber?.dirty || MobileNumber?.touched)">
                                Mobile Number
                            </div>
                        </div>
                    </div>

                    <div class="row mb-4 align-items-center">
                        <div class="col-md-4">
                            <label for="date" class="form-label textbold">Arrival Date<sup
                                    class="text-danger">*</sup></label>
                        </div>
                        <div class="col-md-5">
                            <div class="row">
                                <div clas="col-md-5">

                                    <div class="input-group">
                                        <input placeholder="Date of Travel" matInput [matDatepicker]="pickerdot"
                                            id="DateofTravel" name="DateofTravel" class="form-control"
                                            [(ngModel)]="SIMRegistrationSecModel.DateofTravel" #Traveldate="ngModel"
                                            #DateofTravel="ngModel" required [min]="Startdate" [ngClass]="{
                                    'is-invalid': (DateofTravel?.invalid && IsSubmitted),
                                    'valid': !DateofTravel?.valid && (DateofTravel?.dirty || DateofTravel?.touched)
                               }">
                                        <mat-datepicker-toggle matSuffix [for]="pickerdot"></mat-datepicker-toggle>
                                        <mat-datepicker #pickerdot></mat-datepicker>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="!DateofTravel?.valid && (DateofTravel?.dirty || DateofTravel?.touched)">
                                Arrival Date
                            </div>
                        </div>
                    </div>
                    <div class="row mb-4 align-items-center">
                        <div class="col-md-4">
                            <label for="tdate" class="form-label textbold">Arrival Date<sup
                                    class="text-danger">*</sup></label>
                        </div>
                        <div class="col-md-5">
                            <select class="form-select" aria-label="Default select example"
                                [(ngModel)]="SIMRegistrationSecModel.DateofTravelTentative" name="DateofTravelTentative"
                                id="DateofTravelTentative" #DateofTravelTentative="ngModel" [ngClass]="{
                              'is-invalid': (DateofTravelTentative?.invalid && IsSubmitted),
                              'valid': !DateofTravelTentative?.valid && (DateofTravelTentative?.dirty || DateofTravelTentative?.touched)
                         }">
                                <option selected>Confirmed</option>
                                <option>Tentative</option>
                            </select>
                            <div
                                *ngIf="!DateofTravelTentative?.valid && (DateofTravelTentative?.dirty || DateofTravelTentative?.touched)">
                                Arrival Date
                            </div>
                        </div>
                    </div>

                    <div class="row mb-4 align-items-center">
                        <div class="col-md-4">
                          <label for="FirstName" class="form-label textbold">First Name<sup class="text-danger">*</sup></label>
                        </div>
                        <div class="col-md-5">
                          <input type="text" class="form-control first-letter" id="FirstName" aria-label="First name" required
                            [(ngModel)]="SIMRegistrationSecModel.FirstName" #FirstName="ngModel" name="FirstName">
                          <div *ngIf="!FirstName?.valid && (FirstName?.dirty || FirstName?.touched)">
                            First Name
                          </div>
                        </div>
                      </div>
                      <div class="row mb-4 align-items-center">
                        <div class="col-md-4">
                          <label for="LastName" class="form-label textbold">Last Name<sup class="text-danger">*</sup></label>
                        </div>
                        <div class="col-md-5">
                          <input type="text" class="form-control first-letter" id="LastName" aria-label="Last name"
                            [(ngModel)]="SIMRegistrationSecModel.LastName" required name="LastName" #LastName="ngModel">
                          <div *ngIf="!LastName?.valid && (LastName?.dirty || LastName?.touched)">
                            Last Name
                          </div>
                        </div>
                      </div>
                      <div class="row mb-4 align-items-center">
                        <div class="col-md-4">
                          <label for="mobile" class="form-label textbold">Indian Mobile Number<sup
                              class="text-danger">*</sup></label>
                        </div>
                        <div class="col-md-5">
                          <div class="row">
                            <div class="col-md-4">
                              <input type="text" value="+91" class="form-control" disabled>
                            </div>
                            <div class="col-md-8">
                              <input type="text" class="form-control" id="Indianmobilenumber" aria-label="mobile" readonly
                                [(ngModel)]="SIMRegistrationSecModel.Indianmobilenumber" required name="Indianmobilenumber"
                                #Indianmobilenumber="ngModel" (keypress)="ValidateMobilenumber($event)" minlength=10 maxlength=10>
                              <div *ngIf="!Indianmobilenumber?.valid && (Indianmobilenumber?.dirty || Indianmobilenumber?.touched)">
                                Mobile Number
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    <div class="row mb-4 align-items-center">
                        <div class="col-md-4">
                            <label for="email" class="form-label textbold">Email ID<sup
                                    class="text-danger">*</sup></label>
                        </div>
                        <div class="col-md-5">
                            <input type="email" class="form-control" id="EmailID" aria-label="email"
                                pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
                                [(ngModel)]="SIMRegistrationSecModel.EmailID" required name="EmailID" #EmailID="ngModel"
                                [ngClass]="{
                              'is-invalid': (EmailID?.invalid && IsSubmitted),
                              'valid': !EmailID?.valid && (EmailID?.dirty || EmailID?.touched)
                         }">
                            <div *ngIf="!EmailID?.valid && (EmailID?.dirty || EmailID?.touched)">
                                Email ID
                            </div>
                        </div>
                    </div>
                    <div class="row mb-4 align-items-center">
                        <div class="col-md-4">
                            <label for="email" class="form-label textbold">Canadian address*<sup
                                    class="text-danger">*</sup></label>
                        </div>
                        <div class="col-md-5">
                            <textarea type="text"  class="form-control"
                               
                                [(ngModel)]="SIMRegistrationSecModel.Canadianaddress" required name="Canadianaddress" #Canadianaddress="ngModel"
                                [ngClass]="{
                              'is-invalid': (Canadianaddress?.invalid && IsSubmitted),
                              'valid': !Canadianaddress?.valid && (Canadianaddress?.dirty || Canadianaddress?.touched)
                                }"></textarea>

                            <div *ngIf="!Canadianaddress?.valid && (Canadianaddress?.dirty || Canadianaddress?.touched)">
                             Address
                            </div>
                        </div>
                    </div>
                    <div class="row mb-4 align-items-center">
                        <div class="col-md-4">
                          <label for="Province" class="form-label textbold">Province<sup
                              class="text-danger">*</sup></label>
                        </div>
                        <div class="col-md-5">
                          <select class="form-select" aria-label="Default select example"
                            [(ngModel)]="SIMRegistrationSecModel.Province" name="Province" id="Province"
                            #Province="ngModel">
                            <option *ngFor="let Item of ProvinceList" [ngValue]="Item" [selected]="Item === 'Alberta'">
                              {{Item}}
                            </option>
                          </select>
                          <div *ngIf="!Province?.valid && (Province?.dirty || Province?.touched)">
                            Required
                          </div>
                        </div>
                      </div>
                    <div class="row mb-4 align-items-center">
                        <div class="col-md-4">
                            <label for="email" class="form-label textbold">Postal Code<sup
                                    class="text-danger">*</sup></label>
                        </div>
                        <div class="col-md-5">
                            <input type="text"  class="form-control"
                               
                                [(ngModel)]="SIMRegistrationSecModel.PostalCode" required name="PostalCode" #PostalCode="ngModel"
                                [ngClass]="{
                              'is-invalid': (PostalCode?.invalid && IsSubmitted),
                              'valid': !PostalCode?.valid && (PostalCode?.dirty || PostalCode?.touched)
                                }">

                            <div *ngIf="!PostalCode?.valid && (PostalCode?.dirty || PostalCode?.touched)">
                                Postal Code
                            </div>
                        </div>
                    </div>

                    <div class="row mb-4 align-items-center">
                        <div class="col-md-4">
                            <label for="email" class="form-label textbold">University Name</label>
                        </div>
                        <div class="col-md-5">
                            <input type="text"  class="form-control"
                               
                                [(ngModel)]="SIMRegistrationSecModel.UniversityName"  name="UniversityName" #UniversityName="ngModel"
                                [ngClass]="{
                              'is-invalid': (UniversityName?.invalid && IsSubmitted),
                              'valid': !UniversityName?.valid && (UniversityName?.dirty || UniversityName?.touched)
                                }">

                            <div *ngIf="!UniversityName?.valid && (UniversityName?.dirty || UniversityName?.touched)">
                                University Name
                            </div>
                        </div>
                    </div>
                    
                    <div class="row mb-4 align-items-center">

                        <div class="col-md-4">
                            <label for="email" class="form-label textbold">Passport front file<sup
                                    class="text-danger">*</sup></label>
                        </div>
                        <div class="col-md-4">
                            <input class="form-control" placeholder="Front" type="file" id="passportfilename"
                            name="passportfilename"  
                            [(ngModel)]="SIMRegistrationSecModel.passportfilename"
                            #passportfilename="ngModel"  required accept="image/png, image/gif, image/jpeg, .pdg, .PDF"
                            (change)="handleFileInputPassportFront($event.target.files, 'PassportFront')">
                            {{ActionMessageFront}}
                        </div>
                    </div>
                    <div class="row mb-4 align-items-center">
                        <div class="col-md-4">
                            <label for="email" class="form-label textbold">Passport back file<sup
                                    class="text-danger">*</sup></label>
                        </div>
                        <div class="col-md-4">
                            <input class="form-control" placeholder="Front" type="file" id="passportbackfilename"
                            name="passportbackfilename" 
                            [(ngModel)]="SIMRegistrationSecModel.passportbackfilename"
                            #passportbackfilename="ngModel"
                            required accept="image/png, image/gif, image/jpeg, .pdg, .PDF"
                            (change)="handleFileInputPassportFront($event.target.files, 'PassportBack')">
                            {{ActionMessageBack}}
                        </div>
                    </div>
                    <div class="row mb-4 align-items-center">

                        <div class="col-md-4">
                            <label for="email" class="form-label textbold">Visa stamp copy file<sup
                                    class="text-danger">*</sup></label>
                        </div>
                        <div class="col-md-4">
                            <input class="form-control" required placeholder="Front" type="file" id="visafilename"
                            name="visafilename" 
                            [(ngModel)]="SIMRegistrationSecModel.visafilename"
                             #visafilename="ngModel"
                            accept="image/png, image/gif, image/jpeg, .pdg, .PDF"
                            (change)="handleFileInputPassportFront($event.target.files, 'Visa')" >
                            {{ActionMessageVisa}}
                        </div>
                    </div>


                    <div class="row mb-4 align-items-center">
                        <div class="col-12 text-end">
                            <button type="button" class="btn btn-danger" (click)="OnBackButton()"> &lt;
                                Previous</button>
                            &nbsp; &nbsp;
                            <button type="button" class="btn btn-danger" (click)="OnSubmit(SIMRegistrationSecModel)"
                                [disabled]="sf.invalid">Submit
                            </button>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </form>
</div>