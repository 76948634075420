<div class="container">
    <form #SIMRegistration="ngForm" id="SIMRegistration" novalidate>
      <div class="row">


        <div class="col-md-10 offset-md-1 shadow-lg px-md-5 py-5 border bg-white">
          <!-- <h4 class="text-center innerheader my-4">Register your card and save $50 as setup service fee</h4> -->
          <!-- <h4 class="text-center innerheader my-4">Register and get 1st month plan absolutely free worth $45</h4> -->
          
          <div>
            <h4 class="secondheader mb-4 text-center">Gift Voucher</h4>
          </div>

          <div class="row mb-4 align-items-center">
            <div class="col-md-4">
              <label for="FirstName" class="form-label textbold">First Name<sup class="text-danger">*</sup></label>
            </div>
            <div class="col-md-5">
              <input type="text" class="form-control first-letter" id="FirstName" aria-label="First name" required
                [(ngModel)]="giftvoucherRequest.FirstName" #FirstName="ngModel" name="FirstName">
              <div *ngIf="!FirstName?.valid && (FirstName?.dirty || FirstName?.touched)">
                First Name
              </div>
            </div>
          </div>
          <div class="row mb-4 align-items-center">
            <div class="col-md-4">
              <label for="LastName" class="form-label textbold">Last Name<sup class="text-danger">*</sup></label>
            </div>
            <div class="col-md-5">
              <input type="text" class="form-control first-letter" id="LastName" aria-label="Last name"
                [(ngModel)]="giftvoucherRequest.LastName" required name="LastName" #LastName="ngModel">
              <div *ngIf="!LastName?.valid && (LastName?.dirty || LastName?.touched)">
                Last Name
              </div>
            </div>
          </div>
          <div class="row mb-4 align-items-center">
            <div class="col-md-4">
              <label for="mobile" class="form-label textbold">Indian Mobile Number<sup
                  class="text-danger">*</sup></label>
            </div>
            <div class="col-md-5">
              <div class="row">
                <div class="col-md-4">
                  <input type="text" value="+91" class="form-control" disabled>
                </div>
                <div class="col-md-8">
                  <input type="text" class="form-control" id="IContactNumber" aria-label="mobile"
                    [(ngModel)]="giftvoucherRequest.IContactNumber" required name="IContactNumber"
                    #IContactNumber="ngModel" (keypress)="ValidateMobilenumber($event)" minlength=10 maxlength=10>
                  <div *ngIf="!IContactNumber?.valid && (IContactNumber?.dirty || IContactNumber?.touched)">
                    Mobile Number
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-4 align-items-center">
            <div class="col-md-4">
              <label for="email" class="form-label textbold">Email address<sup class="text-danger">*</sup></label>
            </div>
            <div class="col-md-5">
              <input type="email" class="form-control" id="Email" aria-label="email"
                [(ngModel)]="giftvoucherRequest.Email" required name="Email" #Email="ngModel" pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$" [email]="true">
              <div *ngIf="!Email?.valid && (Email?.dirty || Email?.touched)">
                Email ID
              </div>
            </div>
          </div>
     
          <div class="row mb-4 align-items-center">
            <div class="col-md-4">
              <label for="InternationalNumber" class="form-label textbold">Koodo mobile number<sup class="text-danger">*</sup></label>
            </div>
            <div class="col-md-5">
              <input type="text" class="form-control first-letter" id="InternationalNumber" aria-label="Last name"
                [(ngModel)]="giftvoucherRequest.InternationalNumber" required name="InternationalNumber" #InternationalNumber="ngModel">
              <div *ngIf="!InternationalNumber?.valid && (InternationalNumber?.dirty || InternationalNumber?.touched)">
                Koodo mobile number
              </div>
            </div>
          </div>

          <div class="row mb-4 align-items-center">

            <div class="col-md-4">
                <label for="email" class="form-label textbold">Upload Receipt<sup
                        class="text-danger">*</sup></label>
            </div>
            <div class="col-md-5">
                <input class="form-control" placeholder="Front" type="file" id="MiddleName"
                name="MiddleName"  
                [(ngModel)]="giftvoucherRequest.MiddleName"
                #passportfilename="ngModel"  required accept="image/png, image/gif, image/jpeg, .pdg, .PDF"
                (change)="handleFileInput($event.target.files, 'PaymentReceipt', giftvoucherRequest)">
                {{ActionMessageFront}}
                {{ActionMessageVisa}}
            </div>
        </div>

          <div class="row mb-4 align-items-center">
            <div class="col-md-4">
             <span class="text-success">  {{Requestsuccessmessage}} </span>  
            </div>
            <div class="col-md-5 text-end">
              <button type="submit" class="btn btn-danger" (click)="onSubmit(giftvoucherRequest)"
                [disabled]="SIMRegistration.invalid">Submit</button>
            </div>
          </div>


        </div>
      </div>

    </form>
  </div>